/* -------------------------Styles for Dialogs Start-------------------------*/
.mat-dialog-container {
  padding: 0px !important;
  z-index: 9999;
}

::ng-deep .mat-dialog-container {
  z-index: 9999;
}

.header {
  background: #444751;
  color: #fff;
  margin: 0;
  font-size: 16px ;
  position: relative;
  padding:  19px 50px 18px 20px;  /*19px 50px 18px 5px;*/
}

.webpage-header {
  padding: 10px 50px 5px 20px;
}

.loader-message {
  min-height: 100px;
  text-align: center;
}

.content {
  padding: 10px;
  max-height: 500px;
  overflow: auto;
}

mat-dialog-container .content {
  min-height: 250px;
}

@media only screen and (min-width: 660px) and (max-width: 812px) {
  .content {
    max-height: 230px !important;
    overflow: auto !important;
  }
}

.footer {
  text-align: right;
  margin: 0;
  padding: 10px 15px 10px;
  background: #f5f5f5;
  width: 100%;
}

.dialog-close-button {
	position: absolute;
	right: 20px;
	top: 20px;
	color: #d1d1d1 !important;
	cursor: pointer;
}

.element-gap {
  padding: 10px;
}


@media only screen and (max-width: 767px) {
  .header {
    padding: 15px 50px 15px 15px;
    font-size: 1.15rem;
  }

  .webpage-header {
    padding: 5px 0px 0px 10px;
  }

  .content {
    padding: 5px;
  }

  .footer {
    padding: 5px 5px 5px;
  }

  .footer button {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .dialog-close-button {
    right: 15px;
    top: 15px;
  }

  .element-gap {
    padding: 5px;
  }
}

cdk-global-overlay-wrapper {
  overflow: auto;
  pointer-events: auto;
}

uxlib-dialog {
  .mat-dialog-content {
    overflow: auto !important;
  }
 .mat-dialog-container {
   overflow: hidden !important;
  .mat-dialog-content {
    overflow: auto !important;
  }
 }
}

/* ------------------------Styles for Dialogs end-----------------*/
