.menu-icon {
  width: 32px;
  height: 32px;
  z-index: 10;
}

.menu {
  background-image: url('/assets/images/icos.png');
  background-repeat: no-repeat;
}

.menu a:before {
  position: absolute;
  left: 8px;
  top: 12px;
  overflow: hidden;
  text-indent: -9999px;
  content: "";
  background: url('/assets/images/icos.png') no-repeat 9999px 0;
}




.menu  .ico1{
  background-position: 2px 7px;
}

.menu  .ico2 {
  background-position: 2px -25px;
}

.menu .ico3 {
 background-position: 2px -61px;
}

.menu .ico4 {
   background-position: 2px -93px;
}

.menu .ico5 {
   background-position: 2px -138px;
}

.menu .ico6 {
   background-position: 2px -176px
}

.menu .ico7{
  background-position: 2px -234px;
}
.menu .ico8{
  background-position: 2px -201px;
}
 .ico-gb {
  background: url('/assets/images/ico-download.png') no-repeat;
  background-size: 30px;
  
}
 .ico-tou-tier {
  background: url('/assets/images/energy_usage_tier_black.png') no-repeat;
  background-size: 30px;
  
}



.menu-icon-base {
  background-position: -90px -6px;
  // background-position: -77px -3px;
}

.menu-icon-base:hover {
  // background-position: -148px -3px;
  background-position: -135px -3px;
}

/* .active .menu-icon-base {
  // background-position: -5px -3px;
  //background-position: -15px -6px;
} */


.icon-menu-default {
  background-position: -82px -6px;
}

.icon-menu-default:hover {
  background-position: -160px -6px;
}

.icon-menu-default.active {
  background-position: -6px -6px;
}

.icon-menu-devops {
  background-position: -77px -3px;
  /* background: url("/assets/images/app/icon-menu-devops.png") no-repeat center;
  opacity: 0.7; */
}

.icon-menu-devops:hover {
  background-position: -148px -3px;
}

.icon-menu-devops.active {
  background-position: -5px -3px;
}


.icon-menu-dev {
  background-position: -77px -3px;
  /* background: url("/assets/images/app/icon-menu-dev.png") no-repeat center;
  background-size: contain;
  opacity: 0.7; */
}

.icon-menu-dev:hover {
  background-position: -148px -3px;
}

.icon-menu-dev.active {
  background-position: -5px -3px;
}
