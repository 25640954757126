@import '~jsoneditor/dist/jsoneditor.min.css';
/* @import "~@ng-select/ng-select/themes/material.theme.css"; */
@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  height: 100%;
}

body {
  font: 12px/16px Roboto, Arial;
  color: #565f5f;
// max-width:100%;
// width: fit-content;
//  min-width: 320px;
  // background: #e4e4e4 url(/src/assets/images/bg-body.png);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
}

/* .page {
  margin-top: 25px !important;
} */
/* .uxlib-btn { // UXL

  border-radius: 2px!important;
  min-width: 100px!important;
}

footer .mat-expansion-panel-header .mat-expansion-panel-header-title{ // UXL
  color: black !important;
}
footer .footer-top a{ // UXL
  color:black !important;
}
footer .link-container .mat-expansion-panel .mat-expansion-panel-content{// UXL
  font: unset !important;
}

uxlib-login-page-banner .sperator-v{ // UXL
  max-width: 50% !important;
}
 .footer-top .branding {// UXL
font: unset !important;
}
.mat-card-content {// UXL
  // font-size: 12px !important;
}
 */
/*** Utility Classes ***/
// forget password model css changes  
.mat-dialog-container{
  // max-height: 370px !important;
  // height: 370px !important;
  width: 870px !important;
  padding: 0px !important;
  z-index: 1 !important;
  overflow: hidden  !important;
}
.mat-dialog-title{
  background: var(--secondary-color) ;
  // color: ;
  color: #fff;

  position: relative;
  padding: 19px 50px 3px 20px;
  text-transform: uppercase;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: var(--primary-color)!important;
}

.mat-dialog-title button {
  float: right;
  top: -54px;
  right: -24px;
  outline: 0 !important;


}
.mat-card.mat-card.mat-focus-indicator{
  box-shadow: none !important;
}


.mat-dialog-content {
  overflow: hidden !important;
  width: 100% !important;
  // max-height: 30vh!important;
  height: 310px!important;
  margin: 0!important;
}
.mat-dialog-actions.mat-dialog-actions{
  padding: 0px 13px!important;
  background: #f5f5f5;
  margin-bottom: 6px !important;
}
uxlib-dialog .mat-dialog-actions.mat-dialog-actions{
  background-color: #fff !important;
  margin: -22px 1px;
}
//  forget password model css changes 
.font-raleway {
  font-family: Raleway, sans-serif !important;
}

.login-buttons{
  line-height: 40px !important;
}
.text-black {
  color: #000000;
}

.circle {
  width: 50px;
  height: 50px;
  background: #D8D8D8;
  background-color: #D8D8D8;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #979797;
}

.btn-link{
  color:#ffff;


}
.btn-link:hover{
  color: #ffff !important;
}
.rounded-square {
  width: 50px;
  height: 50px;
  background: #D8D8D8;
  background-color: #D8D8D8;
  border-radius: 5%;
  box-shadow: inset 0 0 0 1px #979797;
}

.circle2 {
  width: 50px;
  height: 50px;
  background: #D8D8D8;
  background-color: #D8D8D8;
  border-radius: 50%;
}

.no-box-shadow {
  box-shadow: none !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: black !important;
}



.bg-tansparent {
  background: transparent;
}

.semi-transparent {
  opacity: 0.4;
}

.hand-cursor {
  cursor: pointer;
}

.lightgreen-palette {
  color: #FFFFFF;
  background-color: #4EBA70;
}

.deepgreen-palette {
  color: #FFFFFF;
  background-color: #144444;
}

.navyblue-palette {
  color: #FFFFFF;
  background-color: #007A7A;
}

.lightblue-palette {
  color: #FFFFFF;
  background-color: #009E9E;
}

.green-palette {
  color: #FFFFFF;
  background-color: #004343;
}

.divider-x {
  border-bottom: 3px solid #F2F3F6;
}

.divider-y {
  border-right: 3px solid #F2F3F6;
}

.loading-text {
  color: #999999;
  text-align: right;
  font-weight: bold;
  font-style: italic;
  font-size: 11px;
}

.blinking {
  animation: blinkingText 0.8s infinite;
}

.winter-palette {
  color: #FFFFFF;
  background-color: #848ea5;
}

.spring-palette {
  color: #FFFFFF;
  background-color: #7bcbc6;
}

.summer-palette {
  color: #FFFFFF;
  background-color: #60a404;
}

.fall-palette {
  color: #FFFFFF;
  background-color: #f0ac2c;
}

.icon-switch-account {
  background: url("/assets/images/app/icon-switch-account.png") no-repeat center;
  background-size: 32px 32px;
}

.fxFill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  display: flex;
}

.flex-w-100 {
  flex: 1 1 100%;
  display: flex;
  max-width: 100%;
}

.my-custom-snackbar {
  margin: 0 !important;
  position: absolute;
  right: 523px;
  top: 7px;
}

.blue-link-button {
  color: #35a0d1 !important;
  // margin-left: 3px;
  cursor: pointer;
  vertical-align: middle;
  padding: 5px;
  text-align: left;
  background: #d8ecf6;
  border-radius: 2.5px;
  border: 1px solid #d8ecf6;
}

.blue-link-button a span {
  vertical-align: top;
}

.blue-link-button-text {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.blue-button {
  background-color: #257AA2 !important;
  /* #35a0d1 */
  // style change for aoda
  color: #fff;
}

.blue-button:disabled {
  color: #fff !important;
  background-color: #EFEFEF !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.footer-links-item.collapse.show{
  display: flex !important;
}
.footer-links-item.collapse{
  display: flex;
}

@media only screen and (max-width: 767px){
  .footer-links-item.collapse{
    display: none !important;
  }
}

.img-fluid {
  width: 45px;
  height: 45px;
}

.icon-power-outage {
  background: url('/assets/images/icon_power_outage.png') no-repeat center;
  background-size: contain;
}

.icon-bill-details {
  background: url('/assets/images/icon_bill_breakdown.png') no-repeat center;
  background-size: contain;
  border-radius: 0% !important;
}

.icon-bill-summary {
  background: url('/assets/images/bill-summary-2.png') no-repeat center;
  background-size: contain;
  border-radius: 0% !important;
}
.icon-electricity-usage {
    background: url('/assets/images/energy_usage_black.png') no-repeat center;
    background-size: contain !important;
}
.icon-water-usage {
    background: url('/assets/images/water_usage_black.png') no-repeat center;
    background-size: contain !important;
}
.icon-faq {
    background: url('/assets/images/icon-faq-charcoal.png') no-repeat center;
    background-size: contain !important;
}

/* ----------Account details stylesss start------------*/

/* You can add global styles to this file, and also import other style files */

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */


$header-color:#d7d8da;
$bg-color:#f0f0f0;
$bg-electric:#eed6d7;
$bg-water:#d5edf8;

mat-card-header {
  background-color: var(--secondary-color) !important;
  color: #FFFFFF;
}

mat-card-footer {
  background-color: $header-color;
}

::ng-deep .bgc {
  background-color: $bg-color;
}

::ng-deep .bgc-electric {
  background-color: $bg-electric !important;
}

::ng-deep .bgc-water {
  background-color: $bg-water !important;
}

textarea {
  display: block;
  width: 100% !important;
  height: 102px;
  resize: none;
  border: 1px solid #dedfe1;
  border-radius: 4px;
  margin: 0 0 21px;
  padding: 5px 0 0 5px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}


/* style for popup */

.form-content {
  font-weight: normal;
  //font-size: 13px;
  line-height: 16px;
  padding: 10px 0 0;
  font-weight: bold;
  color: #373b46;
}


.password-content {
  padding: 20px 20px 4px;
}

.required {
  display: block;
  text-align: right;
  margin: 0;
}

.password-content .required+.row-section,
.security-content .required+.row-section,
.email-content .required+.row-section {
  border: 0;
}

.password-content .form label,
.security-content .form label {
  float: left;
  width: 156px;
  margin: 11px 0 0;
}

.password-content .input-holder,
.security-content .input-holder {
  float: left;
  width: 312px;
}


p {
  font-weight: normal;
}


/* style for popup */

.uppcase {
  text-transform: uppercase;
}

.service-header {
  background-color: #f0f0f0;
}

.add {
  background-image: url("/assets/images/add-sign.png");
  width: 32px;
  height: 32px;
}

.ico-01 {
  background-image: url("/assets/images/ico-01.png");
  width: 32px;
  height: 32px;
}

/* for backround start */
.bg {
  margin: 0;
  font: 12px/16px Roboto, Arial;
  color: #565f5f;
  min-width: 320px;
  /* background:#e4e4e4 url(/src/assets/images/bg-body.png); */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
}

/* for bacground end */

a:hover {
  text-decoration: underline !important;
}

.btn-danger:hover , .btn-dark:hover{
  color: #fff !important;
  background-color: #27769B !important;
  border-color: #27769B !important;
}
.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-dark{
    color: #fff;
    background-color: #000000;
}
.path-list{
	  column-count: 4 !important;
    width: fit-content !important;
    max-width: fit-content !important;
}
/* added for billing breakdown  */
.col-value-1 {
  flex: 0 0 100px;
}

.col-value-1 span {
  width: 100%;
  text-align: right;
}

.mat-row:hover:not(.ng-trigger-detailExpand) {
  background-color: #fafafa;
}

.form-label{
	font-size: 14px;
  line-height: 28px;
	font-weight: bold;
  color: #373b46;
}

.asterix{
    color: red;
}

input.mat-input-element {
	font-size: 12px;
	font-weight: bold;
}
// UXL
// .mat-card-title{  
//   color: #4B5353;
//   font-size: 15px;
//   line-height: 20px;
//   font-weight: bold;
// }

/* slider */
.mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
    background-color: #ffd740;
}

 .mat-accent .mat-slider-thumb-label-text {
    color: #000000;
}

/* mat-slider  */
  mat-slider {
  width: 98%;
}

/* ngx switch style */

.switch-medium {
  width: 65px !important;
  height: 30px !important;
  border-radius: 6px !important;
}

.switch-medium.checked small {
  left: 35px !important;
}

.switch-medium small {
  width: 29px !important;
  height: 30px !important;
}

small {
    border-radius: 12% !important;
   }

.mat-form-field-underline{
  position: static !important;
}
.mat-form-field-subscript-wrapper{
  position: static !important;
}


/* .app-header {
  background: #ffffff !important;
  border-bottom: 0 !important;
  box-shadow: 0 0 0 0 #0003 !important;
  padding: 10px 10px 20px 10px;
  font-family: Raleway, sans-serif !important;
  margin-bottom: 20px;
} */
.page-title {
  font-family: 'Raleway';
  text-transform: uppercase;
  font-size: 20px;
  color: #434343;
}


.child--featured {
  display: none !important;
}

.ux-side-nav {
  background-color: #f5f5f5ff;
  color: #434343;
  max-width: 275px;
}

.ux-group-label {
  text-transform: uppercase;
  color: #434343;
  font-weight: bold;
  font-size: 14px;
}

.ux-group-value {
  text-transform: uppercase;
  color: #434343;
  font-size: 12px;
}

.ux-icon-button {
  color: #434343;
  background-color: #efefefff;
  border: 1px solid #434343;
}

.ux-icon-button:hover {
  color: #FFFFFF;
  background-color: #434343;
}

.bg-red {
  background-color: var(--primary-color);
}

.border-red {
  border-color: var(--primary-color) !important;
}

.border-medium {
  border-width: 3px !important;
}

.panel-title {
  font-family: 'Raleway';
  text-transform: uppercase;
  font-size: 20px;
  color: #434343;
  font-weight: bold;
}

.mat-expansion-panel-header.bg-red:hover {
  background-color: var(--primary-color) !important;
  opacity: 0.8;
}

.mat-expansion-panel-header.bg-red:focus {
  background-color: var(--primary-color) !important;
}

#loaderdiv{
  position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999999999;
	overflow: hidden;
	background-color: #efefef99;
}

.heading {
  text-transform: capitalize;
  font-weight: bold;
}

.mat-error{
  color:#E91B0C;
}

.loading-text {
  color: #999999;
  text-align: right;
  font-weight: bold;
  font-style: italic;
  font-size: 11px;
}

.blinking {
  animation: blinkingText 0.8s infinite;
}

.SUBMITTED {
  /* color: #42ab55 !important; */
}

.QUEUED {
  color: #8D6507 !important;
}

.CIS {
  color: #5C3CAF !important;
}

.EXCEPTION {
  color: #980000 !important;
}

.BLANKET {
  color: #00767A !important;
}

.DRAFT {
  color: #bbbbbb !important;
}

.COMPLETED {
  color: #008000 !important;
}

.BILLING {
  color: #1155cc !important;
}

.severity-H {
  color: #dc3545!important;
}

.severity-M {
  color: #ffc107!important;
}

.severity-L {
  color: #17a2b8!important;
}

.severity-S {
  color: #198754!important;
}

.severity-F {
  color: #dc3545!important;
}

.bg-severity-H {
  color: #dc3545!important;
}

.bg-severity-M {
  color: #ffc107!important;
}

.bg-severity-L {
  color: #17a2b8!important;
}

.bg-severity-S {
  color: #198754!important;
}

.bg-severity-F {
  color: #dc3545!important;
}

.branding >p {
  margin-top: 1rem !important;
}
uxlib-footer{
	.mat-expansion-panel-header {
    padding:9px !important;
}
.mat-expansion-indicator::after{
  color: #333 !important;
}
}

// .genesys-mxg-frame {
//   margin-right: -17px !important;
//   margin-bottom: -15px !important;
// }