$billing-summary-button-color:#35a0d1 !important;
$billing-summary-button-hover-color:#1f79a2 !important;
$billing-summary-cancel-button-color:#393d46 !important;
$mat-menu-hover-color: #6490a9 !important;
@mixin ui-button {
  color: #fff !important;
  margin: 5px !important;
  text-transform: uppercase;
  border-radius: 2px !important;
}
.ui-action-btn {
  @include ui-button;
  background: $billing-summary-button-color;
  
}
.ui-action-btn-cancel {
  @include ui-button;
  background: $billing-summary-cancel-button-color;
}

.ui-action-btn:hover, .ui-action-btn-cancel:hover  {
  background-color: $billing-summary-button-hover-color;
}

/* Download Button Bill History Style Start*/
.btn-blue{
   background-color: $billing-summary-button-color;
   box-shadow: 0 1.5px 3.5px 0 $billing-summary-button-hover-color;
} 

.mat-menu-item {
  /* background-color: #1f79a2 !important; */
   color: #383c46 !important;
   background-color: #c1dded !important;
   border-bottom: 1px solid #9ec5dd;
}
::ng-deep .mat-menu-content {
	padding: 0px !important;
}
.mat-menu-item:hover {
	background-color: $mat-menu-hover-color;
	text-decoration: underline;
}
/* Download Button Bill History Style End*/
.card-action .btn-link-box,
.summary-action .btn-link-box {
  background-color: #f6f6f6 !important;
  width: 250px;
  height: 60px;
  border-radius: 5px !important;
  padding: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

  .link-title {
    font-size: 15px;
    line-height: 5px;
    padding-top: 10px;
    text-transform: uppercase;
  }

  .link-subtitle {
    font-size: 12px;
    line-height: 5px;
  }
}

/* cust lookup button styles added */
.action-btn {
  cursor: pointer;
  margin-left: 10px;
  display: block;
  border: 1px solid #ababac;
  border-radius: 3px;
  color: #444752;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding: 10px 5px;
  background: #edeff2;
  background: linear-gradient(to bottom, #edeff2 0%, #e9ebef 3%, #e4e6ea 65%, #dddfe3 100%);
  font-weight: bold;
  width: 75%;
}
.view-customerinfo-btn {
  cursor: pointer;
  margin-left: 10px;
  display: block;
  border: 1px solid #ababac;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  padding: 10px 5px;
  background: #107EB1;
  /* #149dde */
  font-weight: bold;
  white-space: nowrap;
  width: 75%;
}

