/*** Material Override ***/
.mat-app-background {
  background-color: #f7f9fa !important;
}

.mat-drawer-container {
  background-color: transparent !important;
}

.mat-tab-group {
  min-height: 50px;
}

.mat-tab-label {
  width: 33.33%;
  opacity: 1 !important;
  color: #757575;
  /* #35a0d1;  #7a7a7a #fff*/
  font-size: 16px;
  text-transform: uppercase !important;
  background: #ffffff;
  /* #363843 */
}

.mat-tab-label:hover {
  background: #1B7C9D;
  /* background:  #004343; */
  color: #fff !important;
}

.mat-tab-disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

.mat-tab-disabled:hover {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

.mat-ink-bar {
  background-color: #1B7C9D !important;
  opacity: 0.1 !important;
}

.mat-ink-bar:hover {
  background-color: #1B7C9D !important;
}

.mat-tab-label-active {
  font-weight: bold !important;
  color: #000000de !important;
  background: #f1be48ff !important;
  opacity: 1 !important;
}

.mat-tab-link {
  font-weight: bold;
  font-size: 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #eeeeeeff;
  color: #999999;
}

.mat-tab-link:hover {
  background: #f1be48ff !important;
  color: #434343ff !important;
  opacity: 0.7 !important;
}

::ng-deep .mat-ink-bar {
  background-color: #35a0d1 !important;
}


/*** mat-chip ***/
.mat-chip {
  font-size: 13px !important;
}

.mat-standard-chip {
  border-radius: 4px !important;
}

mat-chip {
  height: unset !important;
  font-weight: bold !important;
}

mat-chip.ux-chip:not(.mat-chip-selected) {
  background-color: transparent !important;
  color: #434343 !important;
}

mat-chip.ux-chip.mat-chip-selected {
  background-color: #434343ff !important;
  color: #FFFFFF !important;
}


/*** ***/
.default-sidenav mat-sidenav {
  width: 20%;
}

/*** ***/
.mat-radio-container {
  width: 15px !important;
  height: 15px !important;
}

.mat-radio-label-content {
  margin-top: 4px;
}


/*** Mat Table ***/
.mat-table {
  width: 100%;
}

mat-header-row {
  background-color: var(--primary-color) !important;
  color: #FFFFFF !important;
}

mat-row:nth-child(even) {
  background-color: #f5f5f5ff;
}

mat-row:nth-child(odd) {
  background-color: #ffffff;
}

.mat-header-cell {
  color: #FFFFFF !important;
}

mat-cell, mat-header-cell {
  font-size: 12px !important;
}

mat-cell button {
  font-size: 12px !important;
}

mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
  padding-left: 10px !important;
}

mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {
  padding-right: 10px !important;
}

mat-cell.sl, mat-header-cell.sl, mat-footer-cell.sl {
    max-width: 40px;
}

/* mat-row, mat-footer-row {
  height: 40px;
} */

mat-row:hover {
  background-color: #cecece !important;
  color: #bbbbbb !important;
}

td.mat-footer-cell {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0,0,0,.12);
}


.mat-tooltip {
  cursor: pointer;
  font-size: 14px !important;
}

.mat-card-header-text {
  margin: 0 10px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #007bff;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #007bff;
}

/* .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 6px 0 13px 0 !important;
  width: 500px;
} */

.mat-body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
  margin-right: 8px !important;
  margin-top: auto;
}

.mat-sort-header-arrow {
  color: #fff !important;
}

.mat-expansion-panel-header,
.mat-expansion-panel-header.mat-expanded {
  height: 36px !important;
  font-size: 14px !important;
}

.mat-expansion-indicator::after {
  border-width: 0 4px 4px 0 !important;
  color: #FFFFFF !important;
  padding: 4px !important;
  margin-top: -4px;
  margin-left: 4px;
}

// .mat-expansion-panel-body {
//     padding: 0 10px 10px !important;
// }

.mat-form-field-wrapper {
    padding-bottom: 0.25em !important;
}

.mat-expansion-panel-body {
    padding: 0 10px 16px !important;
}